import { Button } from 'antd';
import { FooterWrapper } from './styles';

const Footer = ({ isCanvasEmpty, onClickScan, onCancel, onFinish }) => {
  return (
    <FooterWrapper>
      <Button type='primary' className='secondary' id='cancel-picture' onClick={onCancel} block>
        Cancelar
      </Button>
      <Button
        onClick={onClickScan}
        id='take-picture'
        type='primary'
        block
        icon={<i className='fal fa-scanner-touchscreen mr-2'></i>}>
        {isCanvasEmpty ? 'Escanear' : 'Reintentar'}
      </Button>
      <Button type='primary' className='terciary' id='upload-picture' onClick={onFinish} disabled={isCanvasEmpty} block>
        Subir documento
      </Button>
    </FooterWrapper>
  );
};

export default Footer;
