import { ModalWrapper } from './styles';
import PhotoPreview from './PhotoPreview';
import { useState } from 'react';
import { Spin } from 'antd';
import { PDFDocument } from 'pdf-lib';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

const getId = () => {
  const date = new Date();
  return date.toLocaleDateString().replace(/\//g, '_') + '-' + date.toLocaleTimeString().replace(/:/g, '_');
};

const TakePhoto = ({ onEditSuccess, onEditAbort }) => {
  const [imageB64, setImage] = useState();
  const [loading, setLoading] = useState();

  const photoActions = {
    onFinish: async () => {
      setLoading(true);
      const pdf = await PDFDocument.create();
      const page = pdf.addPage();

      const image = await pdf.embedJpg(imageB64);
      // margin
      const imageDims = image.scaleToFit(page.getWidth() - 50, page.getHeight() - 50);

      page.drawImage(image, {
        x: 25,
        y: 25,
        ...imageDims,
      });

      const content = await pdf.saveAsBase64();
      const size = (await pdf.save()).length;

      setLoading(false);
      onEditSuccess({ content, name: `portal-${getId()}.pdf`, size });
    },

    onCapture: (image) => {
      setImage(image);
    },

    onClear: () => {
      setImage();
    },

    onCancel: () => {
      setImage();
      onEditAbort();
    },
  };

  return (
    <ModalWrapper visible animation={false} footer={false} closable={false}>
      {loading ? (
        <Spin tip='Generando PDF...' indicator={<AiOutlineLoading3Quarters />} />
      ) : (
        <PhotoPreview {...photoActions} />
      )}
    </ModalWrapper>
  );
};

export default TakePhoto;
