import { axios } from '../connection';
import { fileToBase64 } from '../utils';

export const fetchInvoices = async (abortController) =>
  await axios
    .get('/invoices', { signal: abortController.signal })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log('🛠️ ~ file: invoice.js ~ line 8 ~ err', err);
      return null;
    });

export const postInvoices = async (invoices = []) => {
  // split into buys invoice and sales invoices
  const filesConverted = await Promise.all(
    invoices.map(async ({ type, file }) => {
      if (file.isB64) {
        return { type: type, fileName: file.name, document: file.content };
      }

      const b64 = await fileToBase64(file);
      return { type: type, fileName: file.name, document: b64 };
    })
  );

  const invoicesByType = {
    buys: {
      type: 'buys',
      documents: [],
    },
    sales: {
      type: 'sales',
      documents: [],
    },
  };

  filesConverted.forEach((invoice) => {
    let body = { fileName: invoice.fileName, document: invoice.document };
    invoicesByType[invoice.type].documents.push(body);
  });

  const processedInvoices = await Promise.all(
    Object.values(invoicesByType).map(async (requestBody) => {
      return axios
        .post('/invoices', requestBody)
        .then((response) => response.data)
        .catch((err) => {
          console.log('🛠️ ~ file: invoice.js ~ line 45 ~ Object.values ~ err', err);
          return [];
        });
    })
  );

  return processedInvoices.flat();
};
