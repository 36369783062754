import { FileWrapper } from './styles';
import { BiXCircle } from 'react-icons/bi';

const getFileSize = (file) => {
  // show in bytes
  if (file.size < 1024) {
    return [file.size.toFixed(2), 'B'];
  }
  // show in kbytes
  if (file.size / 1024 < 1024) {
    return [(file.size / 1024).toFixed(2), 'KB'];
  }

  return [(file.size / (1024 * 1024)).toFixed(2), 'MB'];
};

const UploadedFile = ({ index, file, type, onChangeType, onDelete }) => {
  const [size, unit] = getFileSize(file);

  const handleType = ({ target }) => {
    onChangeType(index, target.value);
  };

  const handleDelete = () => {
    onDelete(index);
  };

  return (
    <FileWrapper>
      <div className='filename'>{file.name}</div>
      <div className={`invoice-type ${type}`}>
        <select onChange={handleType}>
          <option value='buys' className='buys'>
            Compra
          </option>
          <option value='sales' className='sales'>
            Venta
          </option>
        </select>
      </div>
      <div className='filesize'>
        {size} {unit}
      </div>
      <div className='deletefile' onClick={handleDelete}>
        <BiXCircle size='1rem' />
      </div>
    </FileWrapper>
  );
};

export default UploadedFile;
