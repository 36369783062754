import AppLogo from './AppLogo';
import { AppHeaderWrapper } from './styles';

const AppHeader = () => {
  return (
    <AppHeaderWrapper>
      <AppLogo />
    </AppHeaderWrapper>
  );
};

export default AppHeader;
