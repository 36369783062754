import Modal from 'antd/lib/modal/Modal';
import styled from 'styled-components';

export const ModalWrapper = styled(Modal).attrs((props) => ({
  ...props,
  width: '90%',
  height: '98%',
}))`
  top: 10px;

  & > .ant-modal-content {
    background-color: transparent;
    width: 100%;
    box-shadow: none;

    display: flex;
    flex-direction: column;

    & > * {
      flex: 1;
    }

    & > .ant-modal-body {
      padding: 0;
      flex: 99;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;
