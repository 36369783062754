import { pdfjs, Document, Page } from 'react-pdf';
import { PreviewWrapper } from './styles';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const InvoicePreview = ({ document, isTemporal, visible, onClose }) => {
  const getInvoiceSrc = () => {
    if (isTemporal) {
      return `data:application/pdf;base64,${document}`;
    }

    return document.url;
  };

  return (
    <PreviewWrapper visible={visible} onClose={onClose}>
      {visible && (
        <Document file={getInvoiceSrc()}>
          <Page pageNumber={1} />
        </Document>
      )}
    </PreviewWrapper>
  );
};

export default InvoicePreview;
