import { Button, Layout } from 'antd';
import styled from 'styled-components';
import colors from '../colors';

const { Sider, Header, Footer, Content } = Layout;

export const MainLayoutWrapper = styled(Layout)`
  min-height: 100vh;
`;

export const MainLayoutInner = (props) => {
  return (
    <MainLayoutInnerWrapper>
      <MainLayoutInnerLayout>{props.children}</MainLayoutInnerLayout>
    </MainLayoutInnerWrapper>
  );
};

const MainLayoutInnerLayout = styled(Layout)`
  height: 100%;
  padding: 2rem;
  gap: 2rem;
`;

const MainLayoutInnerWrapper = styled(Content)`
  height: 100%;
  width: 100%;
`;

export const AppContent = styled(Content)`
  background-color: ${colors.white};
  height: 100%;
  border-radius: 5px;
  ${colors.shadow}
`;

export const AppMenuWrapper = styled(Sider)`
  padding: 1rem;
  height: fit-content;
  border-radius: 5px;
  background-color: ${colors.white};
  ${colors.shadow}

  & .ant-layout-sider-children {
    display: flex;
    flex-direction: column;

    gap: 10px;
  }

  & button {
    margin-top: 1rem;
  }
`;

export const AppMenuMobileWrapper = styled.div`
  width: 100%;
  position: sticky;
  top: 20px;

  padding: 0 1rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: 5px;
  background-color: ${colors.white};
  ${colors.shadow}

  & > div {
    flex: 2;
    width: 100%;
  }

  & > svg {
    margin-left: 10px;
    cursor: pointer;
    transform: rotate(135deg);
    color: ${colors.graydark};
  }

  background-color: ${colors.white};

  z-index: 1;
`;

export const AppHeaderWrapper = styled(Header).attrs({
  id: 'app-header',
})`
  z-index: 1000;
  line-height: initial;
  padding: 0 15px;
  height: 70px;
  width: 100%;
  background: ${colors.mainpink};
  display: flex;
  align-items: center;
  justify-content: center;

  & > .app-logo {
    width: 100%;
    height: 100%;
  }
`;

export const AppLogoWrapper = styled.div.attrs({
  className: 'app-logo',
})`
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    width: 100%;
    height: auto;
    object-fit: scale-down;
  }
`;

export const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  padding: 0.5rem;

  font-weight: 600;

  & > svg {
    font-size: 1.2rem;
    vertical-align: top;
  }

  & > span {
    vertical-align: top;
  }

  color: ${(props) => props.selected && colors.mainpink};

  transition: color 500ms;

  cursor: pointer;

  ${({ mobile }) => {
    if (mobile) {
      return `
        justify-content: center;
      `;
    }
  }}
`;

export const AppFooterWrapper = styled(Footer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: ${colors.secondarybackground};
  color: ${colors.background};
`;

export const LogoutButtonWrapper = styled(Button).attrs((props) => ({
  ...props,
  className: 'secondary',
}))`
  font-weight: 600;
`;
