import styled from 'styled-components';
import colors from '../colors';

const NoData = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
  background-color: ${colors.background};
  color: ${colors.secondarybackground};

  min-height: 6rem;

  padding: 1rem;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default NoData;
