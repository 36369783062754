import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './Auth/PrivateRoute';
import MainContainer from './MainContainer';
import Login from './Login';

function App() {
  return (
    <Routes>
      <Route path='login' element={<Login />} />
      <Route path='/*' element={<PrivateRoute component={<MainContainer />} />} />
    </Routes>
  );
}

export default App;
