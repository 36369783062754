import { AppLogoWrapper } from './styles';
import logoW from '../assets/app/logo.png';

const AppLogo = () => {
  return (
    <AppLogoWrapper>
      <img src={logoW} style={{ maxWidth: 200 }} alt='QwarkOCR Logo' />
    </AppLogoWrapper>
  );
};

export default AppLogo;
