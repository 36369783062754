import { axios } from '../connection';

export const login = async (credentials) => {
  return axios
    .post('/login', { ...credentials })
    .then((response) => {
      return response.status;
    })
    .catch((err) => {
      if (err && err?.response?.status) {
        return err.response.status;
      }
      return 401;
    });
};
