const colors = {
  mainpink: '#c92279',
  background: '#f0f2f5',
  secondarybackground: '#bebfc2',
  green: '#189e6e',
  white: '#ffffff',
  graydark: '#222222',
  shadow: 'box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;',
};

export default colors;
