export const deviceType = {
  TABLET: 'tablet',
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
};

const useIsDeviceType = (...types) => {
  const deviceType = () => {
    const ua = navigator.userAgent;

    const touchDevice = 'ontouchstart' in document.documentElement;

    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
    ) {
      return 'mobile';
    }
    return touchDevice ? 'tablet' : 'desktop';
  };

  return types.includes(deviceType());
};

export default useIsDeviceType;
