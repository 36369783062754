import React, { useState, useRef, useEffect } from 'react';
import Measure from 'react-measure';
import { ImageCapture } from 'image-capture';
import useUserMedia from '../../../../hooks/useUserMedia';
import { drawCanvas } from './utils';
import { Video, Canvas, Wrapper, Container, Flash, Overlay } from './styles';
import Footer from './Footer';

const aspectRatio = 1.414213;

let CAPTURE_OPTIONS = {
  audio: false,
  video: {
    facingMode: 'environment',
    aspectRatio: aspectRatio,
  },
};

// const IMAGE_DPI_SCALE = 7;
const IMAGE_DOWNLOAD = false;
// const IMAGE_FILTER = true;
// const IMAGE_FILTER_TRESHOLD = 100;

const PhotoPreview = ({ onFinish, onCapture, onClear, onCancel }) => {
  const canvasRef = useRef();
  const videoRef = useRef();
  const containerRef = useRef();

  // const [container, setContainer] = useState({ width: 0, height: 0 });
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
  const [isFlashing, setIsFlashing] = useState(false);
  const [imageCapture, setImageCapture] = useState(null);

  const mediaStream = useUserMedia(CAPTURE_OPTIONS);

  useEffect(() => {
    if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
      videoRef.current.srcObject = mediaStream;
      const track = mediaStream.getVideoTracks()[0];
      const imgcptr = new ImageCapture(track);
      setImageCapture(imgcptr);
    }
  }, [mediaStream]);

  const handleCanPlay = () => {
    // calculateRatio(videoRef.current.videoHeight, videoRef.current.videoWidth);
    setIsVideoPlaying(true);
  };

  const handleCapture = () => {
    imageCapture
      .grabFrame()
      .then((blob) => {
        drawCanvas(canvasRef.current, blob, IMAGE_DOWNLOAD, null, null); //(canvas, imagen, descargar, filtro)
        onCapture(canvasRef.current.toDataURL('image/jpeg'));
      })
      .catch((error) => console.log(error));
    setIsFlashing(true);
  };

  const handleClear = () => {
    const context = canvasRef.current.getContext('2d', { alpha: false });
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setIsCanvasEmpty(true);
    setIsVideoPlaying(true);
    onClear();
  };

  const handleEndFlashing = () => {
    setIsFlashing(false);
    setIsCanvasEmpty(false);
    setIsVideoPlaying(false);
  };

  if (!mediaStream) {
    return null;
  }

  const footerProps = {
    onCancel: () => onCancel(),
    isCanvasEmpty: isCanvasEmpty,
    onClickScan: () => {
      if (isCanvasEmpty) {
        handleCapture();
        return;
      }

      handleClear();
    },

    onFinish: () => {
      onFinish();
    },
  };

  return (
    <Measure bounds>
      {({ measureRef }) => (
        <Wrapper>
          <Container
            ref={containerRef}
            maxHeight={videoRef.current && videoRef.current.videoHeight}
            maxWidth={videoRef.current && videoRef.current.videoWidth}>
            {/* video capture */}
            <Video ref={videoRef} hidden={!isVideoPlaying} onCanPlay={handleCanPlay} autoPlay playsInline muted />
            <Canvas
              ref={canvasRef}
              width={1000}
              height={aspectRatio * 1000}
              cssHeight={`${containerRef.current?.clientHeight}px`}
              cssWidth={`${containerRef.current?.clientWidth}px`}
            />
            <Overlay hidden={!isCanvasEmpty} />
            <Flash flash={isFlashing} onAnimationEnd={handleEndFlashing} />
          </Container>

          <Footer {...footerProps} />
        </Wrapper>
      )}
    </Measure>
  );
};

export default PhotoPreview;
