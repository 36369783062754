import { useAuth } from '../../../Auth';
import { dateFormatter } from '../../../utils';
import { InvoiceCardWrapper } from './styles';

const InvoiceCard = ({ invoice, onClick }) => {
  // TODO : Api devolver el establishment para el replace
  const { establishment } = useAuth();
  const { isTemporal } = invoice;
  const fileName = isTemporal
    ? invoice.fileName
    : invoice.document.name.replace(`EST:${establishment}-FOLDER:invoices-FILE:`, '');

  const invoiceDate = dateFormatter(invoice.invoiceDate);
  const { invoiceType, totalAmount, client } = invoice;

  const type = invoiceType === 'sales' ? 'VENTA' : 'COMPRA';

  return (
    <InvoiceCardWrapper onClick={onClick}>
      <span className='invoiceName'>{fileName}</span>
      {invoiceType === 'sales' && client && <span className='invoiceClient'>{client}</span>}
      <span className='invoiceDate'>{invoiceDate}</span>
      <span className={`invoiceAmount ${invoiceType}`}>
        {Number(totalAmount).toFixed(2)}€ [{type}]
      </span>
    </InvoiceCardWrapper>
  );
};

export default InvoiceCard;
