import styled from 'styled-components';
import { Modal } from 'antd';
import colors from '../../../colors';

export const PreviewWrapper = styled(Modal).attrs(({ visible, onClose }) => ({
  visible: visible,
  onClose: onClose,
  onCancel: onClose,
  footer: false,
}))`
  .ant-modal-body {
    padding: 0px;
  }

  .ant-modal-close-x {
    & > .ant-modal-close-icon {
      background-color: ${colors.mainpink};
      border-radius: 100%;
      padding: 4px;

      & > svg {
        color: ${colors.white};
      }
    }
  }

  .react-pdf__Document {
    width: 100%;

    & .react-pdf__Page {
      margin: 1rem 0;
    }

    & .react-pdf__Page:first-child {
      margin: 0 0;
    }

    & .react-pdf__Page:last-child {
      margin: 0 0;
    }
  }

  .react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
  }

  .react-pdf__Page__textContent {
    display: none;
  }

  .react-pdf__Page__annotations annotationLayer {
    display: none;
  }
`;
