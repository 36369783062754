import styled from 'styled-components';
import { Page } from '../styles';

export const HomeWrapper = styled(Page)`
  display: flex;
  flex-direction: column;

  gap: 1.5rem;

  #last-invoices {
    & #last-invoices-title {
      font-size: 1.1rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }
  }
`;
