import React, { useReducer, useContext, useEffect } from 'react';
import { fetchInvoices } from '../../actions/invoice';
import { useAuth } from '../../Auth';

export const invoiceActions = {
  SET_INVOICES: 'SET_INVOICES',
  ADD_INVOICE: 'ADD_INVOICE',
  ADD_INVOICES: 'ADD_INVOICES',
};

const InvoiceContext = React.createContext();

const invoiceReducer = (state, action) => {
  switch (action.type) {
    case invoiceActions.SET_INVOICES:
      return action.payload;
    case invoiceActions.ADD_INVOICE:
      return [action.payload, ...state];
    case invoiceActions.ADD_INVOICES:
      return [...action.payload, ...state];
    default:
      return state;
  }
};

export const useInvoices = () => {
  const context = useContext(InvoiceContext);

  if (!context) {
    throw new Error('useInvoices should be used inside InvoiceProvider');
  }

  return context;
};

export const InvoiceProvider = ({ children }) => {
  const [invoices, dispatchInvoices] = useReducer(invoiceReducer, null);
  const { token } = useAuth();

  useEffect(() => {
    const abortController = new AbortController();
    // fetch invoices
    const getInvoices = async () => {
      let invoices = await fetchInvoices(abortController);

      if (invoices) {
        dispatchInvoices({ type: invoiceActions.SET_INVOICES, payload: invoices });
      }
    };

    if (token) {
      getInvoices();
    }

    return () => {
      abortController.abort();
    };
  }, [token]);

  return <InvoiceContext.Provider value={{ invoices, dispatchInvoices }}>{children}</InvoiceContext.Provider>;
};
