import styled from 'styled-components';
import colors from '../../../colors';

export const InvoiceCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 25% 25%;

  @media (max-width: 768px) {
    & > .invoiceName {
      grid-column: 1 / span 3;
    }
  }

  padding: 1rem;

  background-color: ${colors.white};

  width: 100%;

  border-radius: 5px;

  font-size: 0.9rem;

  &:hover {
    background-color: #f9f9f9;
  }

  & > * {
    display: inline-block;
    width: max-content;
  }

  & > .invoiceName {
    display: inline-block;
    width: max-content;
    font-weight: 700;
    white-space: normal;
  }

  & > .invoiceAmount {
    font-weight: 600;
    color: ${colors.green};

    .buys {
      color: ${colors.mainpink};
    }
  }
`;
