export const drawCanvas = (canvas, img, download, filter, filterThreshold) => {
  //(canvas, imagen, descargar, filtro)
  const context = canvas.getContext('2d', { alpha: false });

  context.clearRect(0, 0, canvas.width, canvas.height);
  context.beginPath();

  context.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);

  /* B&W FILTER*/
  if (filter) {
    let imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    let threshold = filterThreshold;
    for (var i = 0; i < imageData.data.length; i += 4) {
      imageData.data[i] = imageData.data[i + 1] = imageData.data[i + 2] = imageData.data[i] > threshold ? 255 : 0;
    }
    console.timeEnd('filter');
    context.putImageData(imageData, 0, 0);
  }

  /* DOWNLOAD */
  if (download) {
    let dl = document.createElement('a');
    dl.download = 'scanned doc.png';
    dl.href = canvas.toDataURL('image/png');
    dl.click();
  }
  /*     canvas.getContext('2d').globalCompositeOperation = 'color';
        canvas.getContext('2d').fillStyle = "white";
        canvas.getContext('2d').globalAlpha = 1;  // alpha 0 = no effect 1 = full effect
        canvas.getContext('2d').fillRect(0, 0, img.width, img.height); */
};
