import Axios from 'axios';
import { useEffect } from 'react';

import { useAuth } from '../Auth';

export const BASE_URL = window?._env?.LOCAL_API || 'https://qwarkocr-api.kuarasoftware.es/portal/';

export const axios = Axios.create({ baseURL: BASE_URL });

const ConnectionHandler = () => {
  const { token, setToken, setEstablishment, logout } = useAuth();

  useEffect(() => {
    if (token) {
      axios.interceptors.request.use(
        (config) => {
          config.headers['Authorization'] = 'Bearer ' + token;
          return config;
        },
        (err) => {
          const {
            status = 0,
            data: { message = '' },
            config: { url = '' },
          } = err?.response || {};

          console.log('⛔️ REQUEST ERROR (Target)=> ', url);
          console.log('⛔️ REQUEST ERROR (Message)=> ', message);
          console.log('⛔️ REQUEST ERROR (Code) => ', status);
          console.log('----------------------------------------');

          return err;
        }
      );
      return;
    }
  }, [token]);

  axios.interceptors.response.use(
    (response) => {
      if (response.config.url === '/login') {
        setToken(response.data.token);
        setEstablishment(response.data.establishment);
      }
      return response;
    },
    (err) => {
      const {
        status = 0,
        data: message,
        config: { url = '' },
      } = err?.response || {};
      console.log('⛔️ RESPONSE ERROR (Target) => ', url);
      console.log('⛔️ RESPONSE ERROR (Message) => ', message);
      console.log('⛔️ RESPONSE ERROR (Code) => ', status);

      if (message && message.error_code === 'INVALID_PORTAL_TOKEN') {
        logout();
      }

      return err;
    }
  );

  return null;
};

export default ConnectionHandler;
