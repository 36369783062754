import { Button } from 'antd';
import styled from 'styled-components';
import colors from '../../colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & .header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    & > .title {
      font-size: 1.1rem;
      font-weight: bold;
    }
  }
`;

export const FileWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 25% 25%;

  @media (max-width: 768px) {
    & > .filename {
      grid-column: 1 / span 3;
    }
  }

  gap: 10px;

  padding: 1rem;
  width: 100%;
  background-color: ${colors.white};
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;

  & > .filename {
    font-weight: bold;
    margin-right: auto;
  }

  .buys {
    color: ${colors.green};
  }

  .sales {
    color: ${colors.mainpink};
  }

  & > .invoice-type > select {
    border: none;
    outline: 0px;
  }

  & > .deletefile {
    cursor: pointer;

    display: flex;
    align-items: center;
    color: ${colors.mainpink};
  }
`;

export const UploadedWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 0.5rem;
`;

export const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 1rem;

  position: relative;
  width: 100%;
  height: 100%;
  min-height: 200px;
  background-color: ${colors.background};
  border: 2px dashed ${colors.secondarybackground};
  color: ${colors.secondarybackground};
  border-radius: 5px;

  & input {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & button.opencamera {
    margin-top: 1rem;
  }
`;

export const OpenCameraButton = styled(Button)`
  margin: 10px 0;
`;
