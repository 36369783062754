import styled from 'styled-components';
import AppHeader from '../Layout/AppHeader';
import LoginForm from './LoginForm';

const Login = () => {
  return (
    <LoginWrapper>
      <AppHeader />
      <div id='login-content'>
        <LoginForm />
      </div>
    </LoginWrapper>
  );
};

const LoginWrapper = styled.div`
  height: 100vh;
  background: #e5e5e5;

  & #app-header {
    height: 60px;
  }

  & #login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - 60px);
  }
`;

export default Login;
