import { useState } from 'react';
import NoData from '../NoData';
import InvoiceCard from './InvoiceCard';
import { useInvoices } from './InvoiceContext';
import InvoicePreview from './InvoicePreview';
import { InvoiceListWrapper } from './styles';

const InvoiceList = ({ maxNumber }) => {
  const { invoices } = useInvoices();
  const [selectedIndex, setSelectedIndex] = useState();
  const [showPreview, setShowPreview] = useState(false);

  const handleCardClick = (index) => {
    setSelectedIndex(index);
    setShowPreview(true);
  };

  const handlePreviewClose = () => {
    setShowPreview(false);
    setSelectedIndex();
  };

  const previewProps = {
    document: invoices && invoices[selectedIndex]?.document,
    isTemporal: invoices && invoices[selectedIndex]?.isTemporal,
    visible: showPreview,
    onClose: handlePreviewClose,
  };

  return (
    <>
      <InvoicePreview {...previewProps} />
      <InvoiceListWrapper>
        {!invoices || invoices.length === 0 ? (
          <NoData>Todavía no hay ninguna factura</NoData>
        ) : (
          <>
            {invoices.slice(0, maxNumber).map((invoice, index) => (
              <InvoiceCard key={invoice._id} invoice={invoice} onClick={() => handleCardClick(index)} />
            ))}
          </>
        )}
      </InvoiceListWrapper>
    </>
  );
};

export default InvoiceList;
