import { InvoiceProvider } from '../components/Invoice/InvoiceContext';
import AppFooter from './AppFooter';
import AppHeader from './AppHeader';
import AppMenu from './AppMenu';
import { MainLayoutWrapper, MainLayoutInner, AppContent } from './styles';

const MainLayout = (props) => {
  return (
    <MainLayoutWrapper>
      <AppHeader />
      <MainLayoutInner>
        <AppMenu />
        <InvoiceProvider>
          <AppContent>{props.children}</AppContent>
        </InvoiceProvider>
      </MainLayoutInner>
      <AppFooter />
    </MainLayoutWrapper>
  );
};

export default MainLayout;
