import { isNaN } from 'lodash';

export const fileToBase64 = (file, asURL = false) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (asURL) {
        resolve(reader.result);
        return;
      }

      resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
    };
    reader.onerror = (error) => reject(error);
  });
};

export const dateFormatter = (dat, separator = '-') => {
  const date = new Date(dat);

  if (isNaN(date.getTime())) {
    return null;
  }

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  day = day < 10 ? '0' + day : day;
  month = month < 10 ? '0' + month : month;

  return [day, month, year].join(separator);
};
