import { Navigate } from 'react-router-dom';
import { useAuth } from '.';

const PrivateRoute = ({ component }) => {
  const { token, loading } = useAuth();

  if (!token && !loading) {
    return <Navigate to='/login' />;
  }

  return component;
};

export default PrivateRoute;
