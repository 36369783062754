import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkLoginCredentials, setEstablishmentCredentials, setLoginCredentials } from './actions';

const initialValue = { token: null, loading: true, establishment: null };

const AuthContext = React.createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};

export const AuthProvider = ({ children }) => {
  const [value, setValue] = useState(initialValue);
  const navigate = useNavigate();

  const setLoading = () => {
    setValue({ token: null, loading: true, establishment: null });
  };

  const setToken = (token) => {
    setValue((prev) => ({ ...prev, token }));
    setLoginCredentials(token);
  };

  const setEstablishment = (establishment) => {
    setValue((prev) => ({ ...prev, establishment }));
    setEstablishmentCredentials(establishment);
  };

  const logout = () => {
    setValue((prev) => ({ loading: prev.loading }));
    setLoginCredentials(null);
    setEstablishmentCredentials(null);
    navigate('/login');
  };

  useEffect(() => {
    const [token, establishment] = checkLoginCredentials();
    console.log('🛠️ ~ file: index.js ~ line 45 ~ useEffect ~ establishment', establishment);
    console.log('🛠️ ~ file: index.js ~ line 45 ~ useEffect ~ token', token);
    setValue({ token: token || null, loading: false, establishment: establishment || null });
  }, []);

  const contextValues = {
    ...value,
    setLoading,
    setToken,
    setEstablishment,
    logout,
  };

  return <AuthContext.Provider value={contextValues}>{children}</AuthContext.Provider>;
};
