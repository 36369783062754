import { Route, Routes } from 'react-router-dom';
import MainLayout from '../Layout/MainLayout';
import Home from './Pages/Home';
import Invoices from './Pages/Invoices';

const MainContainer = () => {
  return (
    <MainLayout>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='invoices' element={<Invoices />} />
      </Routes>
    </MainLayout>
  );
};

export default MainContainer;
