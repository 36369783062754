import styled, { css, keyframes } from 'styled-components';
import colors from '../../../../colors';

const flashAnimation = keyframes`
  from {
    opacity: 0.75;
  }

  to {
    opacity: 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
`;

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 10px;

  aspect-ratio: 0.7071067811;

  ${colors.shadow}
`;

export const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  height: ${({ cssHeight }) => cssHeight};
  width: ${({ cssWidth }) => cssWidth};

  zoom: ${({ zoomFactor }) => 1 / zoomFactor};
`;

export const Video = styled.video`
  position: absolute;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  z-index: 2;

  ${({ hidden }) => {
    if (hidden) {
      return `display : none;`;
    }
  }}

  &::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  box-shadow: 0px 0px 20px 56px rgba(0, 0, 0, 0.6);
  border: 2px dashed rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  z-index: 3;

  ${({ hidden }) => {
    if (hidden) {
      return `display : none;`;
    }
  }}
`;

export const Flash = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: #ffffff;

  ${({ flash }) => {
    if (flash) {
      return css`
        animation: ${flashAnimation} 750ms ease-out;
      `;
    }
  }}
`;

export const Button = styled.button`
  width: 75%;
  min-width: 100px;
  max-width: 250px;
  margin-top: 24px;
  padding: 12px 24px;
  background: silver;
`;

export const FooterWrapper = styled.div`
  width: 100%;
  margin-top: 5%;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  gap: 3rem;
`;
