import { useReducer, useState } from 'react';
import { UploadedWrapper, UploadWrapper, Wrapper } from './styles';

import { BiCloudUpload } from 'react-icons/bi';
import UploadedFile from './UploadedFile';
import { Button } from 'antd';
import TakePhoto from './TakePhoto';

import useIsDeviceType, { deviceType } from '../../hooks/useIsDeviceType';
import { cloneDeep } from 'lodash';

const ACTIONS = {
  ADD_FILES: 'ADD_FILES',
  REMOVE_FILE: 'REMOVE_FILE',
  UPDATE_TYPE: 'UPDATE_TYPE',
};

/**
 * @param {Array} state
 * @param {Object} action
 * @returns
 */
const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.ADD_FILES:
      return state.concat(action.files);
    case ACTIONS.REMOVE_FILE:
      return [...state.slice(0, action.fileIndex), ...state.slice(action.fileIndex + 1)];
    case ACTIONS.UPDATE_TYPE:
      const auxState = cloneDeep(state);
      auxState[action.index].type = action.invoiceType;
      return auxState;
    default:
      return;
  }
};

const FileUpload = ({ title, onUploadFinish = () => {} }) => {
  const isMobile = useIsDeviceType(deviceType.MOBILE, deviceType.TABLET);
  const [files, dispatch] = useReducer(reducer, []);
  const [isTakingPhoto, setIsTakingPhoto] = useState(false);

  const updateFiles = (uploadedFiles) => {
    if (uploadedFiles && uploadedFiles.length > 0) {
      const existingFiles = files.map((f) => f.name);
      uploadedFiles = uploadedFiles.filter((f) => {
        if (existingFiles.includes(f.name) || f.type !== 'application/pdf') {
          // show notification
          return false;
        }
        return true;
      });

      if (uploadedFiles.length > 0) {
        dispatch({ type: ACTIONS.ADD_FILES, files: uploadedFiles.map((file) => ({ file, type: 'buys' })) });
      }
    }
  };

  const inputUpload = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let uploadedFiles = [...e.target.files];

    if (uploadedFiles.length === 0) {
      return;
    }

    updateFiles(uploadedFiles);
    e.target.value = '';
  };

  const fileUploadDivEvents = {
    onDragEnter: (e) => {
      e.preventDefault();
      e.stopPropagation();
    },
    onDragOver: (e) => {
      e.preventDefault();
      e.stopPropagation();
    },
    onDragLeave: (e) => {
      e.preventDefault();
      e.stopPropagation();
    },
    onDrop: (e) => {
      e.preventDefault();
      e.stopPropagation();

      let uploadedFiles = [...e.dataTransfer.files];

      if (uploadedFiles.length === 0) {
        return;
      }

      updateFiles(uploadedFiles);
      e.dataTransfer.clearData();
    },
  };

  const handleDelete = (index) => {
    dispatch({ type: ACTIONS.REMOVE_FILE, fileIndex: index });
  };

  const handleUpload = () => {
    if (files.length > 0) {
      onUploadFinish(files);
    }
  };

  const uploadButtonProps = {
    children: `Subir archivo${files.length > 1 ? 's' : ''}`,
    onClick: handleUpload,
    disabled: files.length === 0,
    type: 'primary',
  };

  const editImageFileModalProps = {
    onEditSuccess: ({ content, name, size }) => {
      updateFiles([{ name, type: 'application/pdf', content, isB64: true, size }]);
      setIsTakingPhoto(false);
    },
    onEditAbort: () => {
      setIsTakingPhoto(false);
    },
  };

  const handleTakePhoto = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsTakingPhoto(true);
  };

  const handleChangeInvoiceType = (index, type) => {
    dispatch({ type: ACTIONS.UPDATE_TYPE, index, invoiceType: type });
  };

  return (
    <Wrapper>
      <div className='header'>
        <div className='title'>{title}</div>
        <Button {...uploadButtonProps} />
      </div>
      {files && files.length > 0 && (
        <UploadedWrapper>
          {files.map((fileData, index) => (
            <UploadedFile
              key={fileData.file.name}
              file={fileData.file}
              type={fileData.type}
              onChangeType={handleChangeInvoiceType}
              index={index}
              onDelete={handleDelete}
            />
          ))}
        </UploadedWrapper>
      )}
      <UploadWrapper {...fileUploadDivEvents}>
        <input type='file' accept='application/pdf' onChange={inputUpload} multiple />
        <BiCloudUpload size='5rem' />
        Arrastre y suelte el PDF, o haga click para seleccionar el archivo o tomar una foto.
        {isMobile && (
          <Button className='secondary opencamera' onClick={handleTakePhoto}>
            Click aquí para abrir la camara
          </Button>
        )}
      </UploadWrapper>
      {isTakingPhoto && <TakePhoto {...editImageFileModalProps} />}
    </Wrapper>
  );
};

export default FileUpload;
