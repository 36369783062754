import { useState } from 'react';
import FileUpload from '../../../components/FileUpload';
import InvoiceList from '../../../components/Invoice/InvoiceList';
import { postInvoices } from '../../../actions/invoice';
import LoadingFiles from '../../../components/LoadingFiles';
import { HomeWrapper } from './styles';
import { invoiceActions, useInvoices } from '../../../components/Invoice/InvoiceContext';

const Home = () => {
  const [loading, setLoading] = useState(false);
  const { dispatchInvoices } = useInvoices();

  const maxLastInvoices = 5;

  const handleUpload = async (files) => {
    setLoading(true);
    const invoices = await postInvoices(files);
    dispatchInvoices({ type: invoiceActions.ADD_INVOICES, payload: invoices });
    setLoading(false);
  };

  return (
    <HomeWrapper>
      {loading ? <LoadingFiles /> : <FileUpload title={'Subir archivo/s :'} onUploadFinish={handleUpload} />}
      <div id='last-invoices'>
        <div id='last-invoices-title'>{`Últimas ${maxLastInvoices} facturas : `}</div>
        <InvoiceList maxNumber={maxLastInvoices} />
      </div>
    </HomeWrapper>
  );
};

export default Home;
