import { Button, Checkbox, Form, Input } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { login } from './actions';

const LoginForm = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState();

  const navigate = useNavigate();

  const onFinish = async (values) => {
    const { email, password, remember } = values;
    setLoading(true);
    const loggedStatus = await login({ email, password });
    setLoading(false);
    if (loggedStatus === 200) {
      navigate('/');
    }
  };

  const btnProps = {
    block: 'true',
    htmlType: 'submit',
  };

  return (
    <Wrapper>
      <Form form={form} onFinish={onFinish} initialValues={{ remember: false }} labelCol={{ span: 24 }}>
        <Form.Item name='email' label='Correo electrónico'>
          <Input />
        </Form.Item>
        <Form.Item name='password' label='Contraseña'>
          <Input.Password />
        </Form.Item>
        <Form.Item name='remember' valuePropName='checked'>
          <Checkbox style={{ color: '#3B424D' }}>Recordar credenciales</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button className='secondary' {...btnProps} loading={loading}>
            Acceder
          </Button>
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-width: 200px;

  background: #f8f8fa;
  box-shadow: 0px 4px 8px rgba(91, 92, 102, 0.1);
  border-radius: 8px;
  padding: 2rem;

  & .ant-form-item:last-child {
    margin: 0;
  }

  & .ant-checkbox-checked::after {
    border: none;
  }

  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #222222;
    border-color: #777777;
  }
`;

export default LoginForm;
