import { AppMenuMobileWrapper, AppMenuWrapper, LogoutButtonWrapper, MenuItemWrapper } from './styles';

import { useLocation, useNavigate } from 'react-router-dom';
import { BiHome, BiDetail, BiLogOutCircle } from 'react-icons/bi';

import useIsPortrait from '../hooks/useIsPortrait';
import { useAuth } from '../Auth';
import useIsDeviceType, { deviceType } from '../hooks/useIsDeviceType';

const routes = [
  {
    key: 'home',
    icon: <BiHome />,
    title: 'Inicio',
    route: '/',
  },
  {
    key: 'invoices',
    icon: <BiDetail />,
    title: 'Facturas',
    route: '/invoices',
  },
];

const MenuItems = () => {
  const isMobile = useIsDeviceType(deviceType.MOBILE);
  const isPortrait = useIsPortrait();

  return (
    <>
      {routes.map((route) => (
        <MenuItem key={route.key} item={route} mobile={isMobile || isPortrait} />
      ))}
      {!isMobile && !isPortrait ? <LogoutButton /> : null}
    </>
  );
};

const LogoutButton = () => {
  const { logout } = useAuth();
  const handleClick = () => {
    logout();
  };

  return (
    <LogoutButtonWrapper block onClick={handleClick}>
      Cerrar sesión
    </LogoutButtonWrapper>
  );
};

const AppMenuMobile = () => {
  const { logout } = useAuth();
  const handleClick = () => {
    logout();
  };

  return (
    <AppMenuMobileWrapper>
      <MenuItems />
      <BiLogOutCircle onClick={handleClick} />
    </AppMenuMobileWrapper>
  );
};

const AppMenu = () => {
  const isMobile = useIsDeviceType(deviceType.MOBILE);
  const isPortrait = useIsPortrait();

  if (isMobile || isPortrait) {
    return <AppMenuMobile />;
  }

  return (
    <AppMenuWrapper>
      <MenuItems />
    </AppMenuWrapper>
  );
};

const MenuItem = ({ item, mobile }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const selected = item.route === pathname;

  const handleClick = () => {
    if (!selected) {
      navigate(item.route);
    }
  };

  return (
    <MenuItemWrapper selected={selected} onClick={handleClick} mobile={mobile}>
      {item.icon}
      {(selected || !mobile) && <span>{item.title}</span>}
    </MenuItemWrapper>
  );
};

export default AppMenu;
