import InvoiceList from '../../../components/Invoice/InvoiceList';
import { InvoicesWrapper } from './styles';

const Invoices = () => {
  return (
    <InvoicesWrapper>
      <div id='my-invoices'>Mis facturas :</div>
      <InvoiceList />
    </InvoicesWrapper>
  );
};

export default Invoices;
