import { getCookie, setCookie } from 'react-use-cookie';

const LOGIN_COOKIE = 'QWARK_PORTAL_LOGIN';
const ESTABLISMENT_COOKIE = 'QWARK_PORTAL_ESTABLISHMENT';

export const checkLoginCredentials = () => {
  // check cookies
  let loginCookie = getCookie(LOGIN_COOKIE);
  let estCookie = getCookie(ESTABLISMENT_COOKIE);

  return [loginCookie, estCookie];
};

export const setLoginCredentials = (token) => {
  let days = 1;
  let cookieString = token;

  if (!token) {
    days = -1;
    cookieString = '';
  }

  setCookie(LOGIN_COOKIE, cookieString, { days: days });
};

export const setEstablishmentCredentials = (establishment) => {
  let days = 1;
  let cookieString = establishment;

  if (!establishment) {
    days = -1;
    cookieString = '';
  }

  setCookie(ESTABLISMENT_COOKIE, cookieString, { days: days });
};
