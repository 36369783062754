import styled from 'styled-components';
import { Page } from '../styles';

export const InvoicesWrapper = styled(Page)`
  #my-invoices {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
`;
