import { useEffect, useState } from 'react';
import styled from 'styled-components';
import colors from '../colors';
import { Progress } from 'antd';

const LoadingFiles = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev === 100) return 0;
        return prev + 1;
      });
    }, 50);

    return () => {
      setProgress(100);
      clearInterval(interval);
    };
  }, []);

  return (
    <Wrapper>
      <Progress showInfo={false} percent={progress} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 4rem;
  border-radius: 5px;

  background-color: ${colors.white};
`;

export default LoadingFiles;
